import axiosClient from "../../../services/client";

export const fetchAssessorData = () => async (dispatch) => {
    // try {
    // const response = await axiosClient.get('/assessor/get/', { withCredentials: true });
    const response = { data: false }
    if (response.data) {
        dispatch({
            type: 'FETCH_ASSESSOR_SUCCESS',
            payload: response.data,
        });
    } else {
        // Dummy data fallback when no data is found
        const dummyData = {
            username: "JameelShah",
            assessor_title: "Assessor",
            first_name: "Jameel",
            last_name: "Shah",
            email: "jameel.shah@example.com",
            admin_institution: "Dummy Institution",
        };

        dispatch({
            type: 'FETCH_ASSESSOR_SUCCESS',
            payload: dummyData,
        });
    }
    // } catch (error) {
    //     console.error('Error fetching assessor data:', error.message);
    //     dispatch({
    //         type: 'FETCH_ASSESSOR_FAILURE',
    //         payload: error.message,
    //     });
    // }
};

