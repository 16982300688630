// reducers/assessorReducer.js

const initialState = {
    assessorData: null,
    error: null,
};

const assessorReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_ASSESSOR_SUCCESS':
            return {
                ...state,
                assessorData: action.payload,
                error: null,
            };
        case 'FETCH_ASSESSOR_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default assessorReducer;
