import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { privateSLUGS as SLUGS } from '../../utils/slugs';
import { convertSlugToUrl } from '../../utils/utilities';
import DropdownComponent from '../dropdown';
import SearchBar from "../input/SearchBarComponents";

import { searchStudent } from "../../redux/actions/student";

import { logout } from '../../redux/actions/auth';
import EventBus from '../../utils/EventBus';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchAssessorData } from "../../redux/actions/assessor";



function HeaderComponent({ onLogout }) {
    const navigate = useNavigate();
    const [suggestions, setSuggestions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const assessorData = useSelector((state) => state.assessor.assessorData);

    const handleInputChange = async (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setLoading(true);
        try {
            if (value === "") {
                // If search term is empty, set suggestions to an empty array
                setSuggestions([]);
                setLoading(false);
                return;
            }
            const data = await dispatch(searchStudent(value));
            setSuggestions(data);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        onClick(SLUGS.studentDetail.path, suggestion.student_id);
        setSuggestions([]);
        setSearchTerm();
    };


    function onClick(slug, parameters = {}) {
        navigate(convertSlugToUrl(slug, parameters), { state: parameters });
    }

    const handleInputBlur = () => {
        setTimeout(() => {
            setSuggestions([]);
        }, 200);
    };


    function handleLogout() {
        dispatch(logout());
        onLogout();
    };

    function handleProfile() {
        onClick(SLUGS.userProfile.path, "");
    };


    useEffect(() => {
        // Check if assessorData already exists in the store
        if (!assessorData || assessorData.length === 0) {
            dispatch(fetchAssessorData())
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        } else {
            setLoading(false); // Data already in store, stop loading
        }
    }, [dispatch, assessorData]);

    return (
        <nav className="py-2">
            <div className="text-white flex justify-between items-center ">
                <SearchBar
                    searchTerm={searchTerm}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    suggestions={suggestions}
                    loading={loading}
                    handleSuggestionClick={handleSuggestionClick}
                />
                <div className="flex hidden flex-none md:block px-5">
                    <DropdownComponent
                        label={
                            <div className="flex items-center ">
                                <img
                                    src={assessorData?.picture || 'https://avatars3.githubusercontent.com/u/21162888?s=460&v=4'} // Default image if not available
                                    alt='avatar'
                                    className="h-8 w-8 rounded-full"
                                />
                                <span className="px-3 text-gray-700 ">{assessorData?.username || 'Username'}</span>
                                <div className="h-5 w-5 text-gray-700 ">
                                    <FontAwesomeIcon icon="fa-solid fa-angle-down" />
                                </div>
                            </div>
                        }
                        options={[
                            {
                                label: 'Profile',
                                icon: 'fa-regular fa-user',
                                onClick: handleProfile
                            },
                            {
                                label: 'Logout',
                                icon: 'fa-arrow-right-from-bracket',
                                onClick: handleLogout
                            }
                        ]}
                        position={{
                            top: 52,
                            right: -6
                        }}

                    />
                </div>
            </div>
        </nav>
    );
}

export default HeaderComponent;


