import React from 'react';// Use SidebarProvider instead of SidebarContext
import SidebarComponent from '../components/sidebar/SidebarComponent';
import HeaderComponent from '../components/header/HeaderComponent';
import PrivateRoutes from './PrivateRoutes';
import { SidebarProvider } from '../constant/useSidebar';

function PrivateSection({ onLogout }) {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    return (
        <div className="flex h-screen md:flex-row overflow-hidden bg-white">
        <SidebarProvider>
            {/* Sidebar */}
            <div
                className={`sidebar-layout h-screen transition-all duration-300 ${
                    isSidebarOpen ? 'fixed w-[260px]' : 'absolute w-[80px]'
                }`}
            >
                <SidebarComponent
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                />
            </div>

            {/* Main Content */}
            <div
                className={`flex flex-col w-full transition-all ${
                    isSidebarOpen ? 'ml-[260px]' : 'ml-[80px]'
                } bg-white`}
            >
                {/* Header */}
                <div
                    className={`header-layout fixed w-[calc(100%-80px)] ${
                        isSidebarOpen
                            ? 'md:w-[calc(100%-260px)]'
                            : 'md:w-[calc(100%-80px)]'
                    } z-10`}
                >
                    <HeaderComponent onLogout={onLogout} />
                </div>

                {/* Main Content */}
                <div className="main-layout flex-grow px-4 md:px-8 py-4 mt-[55px] overflow-y-auto">
                    <PrivateRoutes />
                </div>
            </div>
        </SidebarProvider>
    </div>
);
}

export default PrivateSection;
