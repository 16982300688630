import { configureStore } from '@reduxjs/toolkit';
import auth from './reducers/auth';
import hospitals from './reducers/hospitals';
import log from './reducers/log';
import message from './reducers/message';
import student from './reducers/student';
import task from './reducers/task';
import verilog from './reducers/verilog';
import years from './reducers/years';
import assessorReducer from './reducers/assessor';

const store = configureStore({
    reducer: {
        student: student,
        assessor: assessorReducer,
        auth: auth,
        message: message,
        hospitals: hospitals,
        log: log,
        task: task,
        verilog: verilog,
        years: years,

    },

});

export default store;