import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertSlugToUrl } from '../../utils/utilities';


const MenuComponent = ({ children, isOpen }) => {

    return (
        <div className='"sidebar"'>
            {children}
        </div>
    );
};

export default MenuComponent;
