import React, { useState, useEffect, useContext, createContext } from 'react';

export const SidebarContext = createContext();

export function SidebarProvider({ children, defaultItem }) {
    const [currentItem, setCurrentItem] = useState(defaultItem);
    const [isOpen, setIsOpen] = useState(true); // Initialize the sidebar as open

    useEffect(() => {
        if (defaultItem !== currentItem) {
            setCurrentItem(defaultItem);
        }
    }, [currentItem, defaultItem]);

    return (
        <SidebarContext.Provider value={{ currentItem, setCurrentItem, isOpen, setIsOpen }}>
            {children}
        </SidebarContext.Provider>
    );
}

export const useSidebar = ({ isCollapsible, item, items = [] } = {}) => {
    const { currentItem, setCurrentItem, isOpen, setIsOpen } = useContext(SidebarContext);
    const isActive = item === currentItem || items.includes(currentItem);
    const [isExpanded, setIsExpanded] = useState(isActive);

    useEffect(() => {
        if (!isActive && isExpanded) {
            setIsExpanded(false);
        }
        if (isActive && !isExpanded) {
            setIsExpanded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentItem]);

    const onItemClick = () => {
        if (!isCollapsible) {
            setCurrentItem(item);
        }
        setIsExpanded((prev) => !prev);
    };

    return {
        isExpanded,
        isActive,
        onItemClick,
        isOpen,
        setIsOpen
    };
};
