import {
    FETCH_STUDENT,
    FETCH_STUDENT_FAILED,
    SEARCH_STUDENT_SUCCESS,
    SET_MESSAGE,
    UPDATE_STUDENT
} from "../../actionTypes";

import StudentServices from "../../../services/studentServices";

// Fetch student data and store it in Redux
export const fetchStudent = () => async (dispatch) => {
    try {
        // Fetch data from the server
        const data = await StudentServices.getStudents(2017);

        // Dispatch data to Redux store
        dispatch({
            type: FETCH_STUDENT,
            payload: data,
        });
    } catch (error) {
        console.error('Error fetching student data:', error);
        const message = error.response?.data || 'Failed to fetch data';

        // Dispatch failure action
        dispatch({
            type: FETCH_STUDENT_FAILED,
        });

        dispatch({
            type: SET_MESSAGE,
            payload: message,
        });
    }
};

// Update student data in Redux
export const updateStudent = (updatedData) => (dispatch, getState) => {
    const { student } = getState();
    const updatedDataArray = student.data.map(studentItem =>
        studentItem.student_id === updatedData.student_id ? updatedData : studentItem
    );

    // Dispatch update action
    dispatch({
        type: UPDATE_STUDENT,
        payload: updatedDataArray,
    });
};

// Search student data in Redux
export const searchStudent = (searchData) => (dispatch, getState) => {
    const { student } = getState();
    console.log(); // Debug student data
    if (!student.data || !Array.isArray(student.data)) {
        console.error('Student data is not an array or is undefined.');
        return [];
    }
    console.log(student.data)
    const matchingStudents = student.data.filter((student) =>
        student.first_name.toLowerCase().includes(searchData.toLowerCase()) ||
        student.last_name.toLowerCase().includes(searchData.toLowerCase())
    );

    matchingStudents.sort((a, b) => {
        if (a.first_name.toLowerCase().startsWith(searchData.toLowerCase())) return -1;
        if (b.first_name.toLowerCase().startsWith(searchData.toLowerCase())) return 1;
        return 0;
    });

    console.log('Matching students:', matchingStudents); // Debug filtered results
    return matchingStudents;
};
